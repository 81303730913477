import * as React from 'react'

// import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import heroVideo from '../assets/videos/los-angeles-aerial.mp4'
import heroVideoMobile from '../assets/videos/los-angeles-aerial-mobile.mp4'
import heroVideoPosterDesktop from '../assets/videos/los-angeles-aerial.jpg'
import heroVideoPosterMobile from '../assets/videos/los-angeles-aerial-mobile.jpg'

export default function HomePageHero() { 

    const headlines = [
      {
        title: "Elevate Your Online Presence",
        message: "Our team of experts specializes in eCommerce, design, development, and expansion of digital businesses. Expert solutions for any challenge."
      },
      {
        title: "Stand Out in the Digital World",
        message: "From custom website design to e-commerce development, our team has the expertise to help your business stand out in a crowded digital landscape. Let us help you succeed."
      },
      {
        title: "Transform Your Online Business",
        message: "Looking to take your online business to the next level? Our innovative e-commerce and website design solutions can help transform your business and drive growth."
      },
      {
        title: "Designing for Success",
        message: "Design is at the heart of what we do. Let us empower your business with custom e-commerce solutions and designs that make an impact and drive results."
      },
      {
        title: "Create an Unforgettable Online Experience",
        message: "Your website and e-commerce experience can make or break your business. Let our team of professionals help you create an unforgettable online experience that engages your customers and drives conversions."
      }
    ];


    const getRandomHeadline = () => headlines[Math.floor(Math.random() * headlines.length)];
    const { title, message } = getRandomHeadline();

    return (
      <div className='w-full h-168 md:h-168 overflow-hidden relative'>
        {/* <ParallaxProvider>
        <Parallax translateY={-30} className="-mt-7 md:-mt-16"> */}
          <div className='absolute w-full mx-auto z-30 px-10 bottom-10'>
            <div className='container pb-0 md:pb-10 mx-auto'>
                <div className='md:max-w-2xl'>
                  <h1 className='text-2xl font-semibold lg:text-3xl md:mb-0 text-white drop-shadow-md'>Web Design Los Angeles, eCommerce Agency and Gen AI services</h1>
                  <p className="leading-5 text-sm lg:text-md my-3 lg:max-w-2xl text-white pb-5 drop-shadow-md">We are a <strong>Creative Digital Agency</strong> that specializes in Web Design, Shopify and GenAI services in Los Angeles</p>
                  {/* <h1 className='text-3xl mb-4 font-semibold lg:max-w-4xl lg:text-5xl md:mb-2 text-white drop-shadow-md !uppercase'>Empowering the Human Journey through <span className='bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-100 to-gray-900 animate-gradient-x text-transparent bg-clip-text'>Digital Innovation</span></h1> */}
                  {/* <p className="leading-5 text-sm lg:text-base my-5 lg:max-w-2xl mx-auto text-white pb-3 drop-shadow-md">Our team of experts specializes in eCommerce, design, development, and expansion of digital businesses. Expert solutions for any challenge.</p> */}
                  {/* <p>From eCommerce platforms to transformative digital products to data analytics solutions, no task is too difficult for us to handle.</p> */}
                  <div className='w-full max-w-lg grid grid-cols-2 gap-3 md:max-w-md md:gap-6'>
                      <div className=''><a href="/work"><button className='block w-full text-sm lg:text-base antialiased text-md bg-bradient-blue bg-transparent border-2 border-white text-white hover:bg-white hover:text-bradient-blue font-semibold transition duration-200 ease-in-out text-center p-3 md:px-10'>See our work</button></a></div>
                      <div className=''><a href="/contact"><button className='block w-full text-sm lg:text-base antialiased text-md bg-bradient-blue bg-transparent border-2 border-white text-white hover:bg-white hover:text-bradient-blue font-semibold transition duration-200 ease-in-out text-center p-3 md:px-10'>Contact Us</button></a></div>
                  </div>
              </div>
            </div>
          </div>
          <div className='w-full relative z-10 h-240 overflow-hidden after:bg-gradient-linear-blue after:w-full after:sticky after:z-50 after:top-0 after:h-240 after:inline-block -mt-20'>
              <video playsInline muted autoPlay loop className='hidden h-240 lg:inline-block grayscale absolute w-auto min-w-full min-h-full object-cover'
                  poster={heroVideoPosterDesktop}>
                <source src={heroVideo} type="video/mp4" />
              </video>
              <video playsInline muted autoPlay loop className='inline-block grayscale absolute w-auto min-w-full min-h-full object-cover lg:hidden' poster={heroVideoPosterMobile}>
                <source src={heroVideoMobile} type="video/mp4" />
              </video>
          </div>
        {/* </Parallax>
        </ParallaxProvider> */}
      </div>
    )
}